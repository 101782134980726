
























































































































































































































import {
  apiShopLists,
  apiShopSwitchStatus,
  apiShopDelete,
  apiChangeRemark
} from '@/api/shop'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
import { apiOtherLists } from '@/api/order'
import uwGoods from './components/goods.vue'
import {apiConfigGoods, apiGoodsOtherList} from "@/api/goods";
	@Component({
	  components: {
      uwGoods,
	    lsPagination,
	    ExportData,
	    LsDialog,
	    DatePicker
	  }
	})
export default class ShopList extends Vue {
  /** S Data **/

		apiShopLists = apiShopLists;

		pager : RequestPaging = new RequestPaging();

		searchObj = {
		  set_meal_id: '',
		  set_meal_status: '',
		  status: '',
		  shop_info: '',
		  start_time: '',
		  end_time: '',
		  time_type: ''
		};

		otherLists : any = {
		  set_meal: [],
		  pay_status: [],
		  pay_way: []
		};
		/** E Data **/

		/** S Methods **/
		// 获取订单其他 方式数据
		getOtherMethodList () {
		  apiOtherLists().then((res : any) => {
		    this.otherLists = res
		  })
		}

		// 获取商城列表
		getShopLists () : void {
		  this.pager.request({
		    callback: apiShopLists,
		    params: {
		      ...this.searchObj
		    }
		  })
		}

    select_sid : number = 0;
    select_goods_arr : any = [];
		// 修改商城状态
		async shopSwitchStatusEdit (status : number, id : number) : Promise<void> {
		  await apiShopSwitchStatus({ id })
		  this.getShopLists()
		}

		async changeRemarks (text : string, id : number) : Promise<void> {
		  await apiChangeRemark({ id: id, remark: text })
		  this.getShopLists()
		}

    goodsConfig(id : number) {
      this.select_sid = id
      const goodsRef = this.$refs.goodsRef as any
      if (goodsRef) {
        goodsRef.dialogVisible = true // 控制弹窗显示
        goodsRef.getGoodsLists()
      }
    }

    setShopGoods(row : any) {
      console.log('row', row)
      this.select_goods_arr = row.map((item: any) => ({
        goods_id: item.id,
        stock: item.stock,
        price: item.price
      }))
      console.log('this.select_goods_arr', this.select_goods_arr)
      apiConfigGoods({
        goods_arr: this.select_goods_arr,
        sid: this.select_sid
      }).then((res: any) => {
        console.log('res', res)
      })

    }

		// 删除商城
		async onShopDelete (row : any) : Promise<void> {
		  await apiShopDelete({ id: row.id })
		  this.getShopLists()
		}

		// 重置搜索
		reset () : void {
		  Object.keys(this.searchObj).map((key) => {
		    this.$set(this.searchObj, key, '')
		  })
		  this.getShopLists()
		}

		// 去编辑商城
		toShopEdit (id : number | any) : void {
		  this.$router.push({
		    path: '/shop/lists_detail',
		    query: {
		      id: id
		    }
		  })
		}

		// 修改超级管理员
		toSuperAdminUpdate (id : number | any) : void {
		  this.$router.push({
		    path: '/shop/modify_account',
		    query: {
		      id: id
		    }
		  })
		}

		// 去商家后台
		toShop (url : any, status : number) {
		  if (status == 0) return this.$message.error('该商城暂未营业中')

		  if (url.indexOf('https://') === 0) window.open(url)
		  else if (url.indexOf('http://') === 0) window.open(url)
		  else window.open('http://' + url)
		}

		/** E Methods **/

		/** S ods **/
		created () {
		  this.getShopLists()
		  this.getOtherMethodList()
		}
}
